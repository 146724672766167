import { useMutation } from '@tanstack/react-query';
import { TelegramAuthData } from '@telegram-auth/react';

import { personalAreaService } from '../services/personal-area';

export const useGetToken = () => {
  return useMutation({
    mutationFn: (data: TelegramAuthData) => {
      return personalAreaService.getToken(data);
    },
  });
};
