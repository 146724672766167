import { useNavigate } from 'react-router-dom';

export function useTelegram() {
  const tg = (window as any).Telegram?.WebApp;
  const navigate = useNavigate();

  const onClose = () => {
    tg.close();
  };

  tg.MainButton.hide();

  if (window.location.search || window.location.pathname !== '/') {
    tg.BackButton.show();
  } else {
    tg.BackButton.hide();
  }

  tg.BackButton.onClick(() => {
    navigate(-1);
  });

  return {
    onClose,
    tg,
    initData: tg?.initDataUnsafe,
  };
}
