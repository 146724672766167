import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

import type { PayloadAction } from '@reduxjs/toolkit';

interface MarginState {
  endDate: string | null;
  startDate: string | null;
}

const initialState: MarginState = {
  endDate: null,
  startDate: null,
};

export const marginSlice = createSlice({
  name: 'margin',
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
    },
  },
});

export const marginActions = marginSlice.actions;

export const selectMargin = (state: RootState) => state.margin;

export default marginSlice.reducer;
