import { AxiosResponse } from 'axios';

import { request } from '../axios';
import { GetAdminResType } from '../types/admin';

export const adminService = {
  getAdmin: async () => {
    return request.get<void, AxiosResponse<GetAdminResType>>('/admins').then((res) => res.data);
  },
};
