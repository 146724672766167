import { useEffect, useState } from 'react';

import { Loader } from '../loader';
import { adminService } from '../../api/services/admin';
import { useActions } from '../../features/hooks/useRedux';
import { useTelegram } from '../../features/hooks/useTelegram';
import { MessageToTheGuest } from '../message-to-the-guest';

interface GuardLayoutProps {
  children: React.ReactNode;
}

export const GuardLayout = ({ children }: GuardLayoutProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const { tg } = useTelegram();

  const { setUser } = useActions();

  const requestCheckAuth = async () => {
    try {
      const { user } = await adminService.getAdmin();

      setUser(user);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    requestCheckAuth();
  }, []);

  if (isLoading) return <Loader />;

  if (!tg.initDataUnsafe || !tg.initDataUnsafe.user) return <MessageToTheGuest />;

  return <div>{children}</div>;
};
