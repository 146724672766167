import s from '../styles/message-guest.module.scss';

export const MessageToTheGuest = () => {
  return (
    <div className={s.block}>
      <p>
        Вас приветствует баннерная рекламная сеть в Telegram TGAds! Если вы хотите разместить баннер в каналах
        Узбекистана, России или Казахстана пишите на <a href="https://t.me/tgadsuzadmin">@tgadsuzadmin</a>
      </p>
    </div>
  );
};
