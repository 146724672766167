import { Button, Drawer } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Menu } from './menu';
import { Logout } from './logout';

import LangSwitcher from '../lang-switcher';
import { CheckPermissionContent } from '../../layouts/check-permission-content';

import s from '../../../styles/header.module.scss';
import { useTelegram } from '../../../features/hooks/useTelegram';
import { useActions, useAppSelector } from '../../../features/hooks/useRedux';
import { selectOrder } from '../../../features/store/slices/order';
import { PERMISSION_CREATE_ORDER } from '../../../features/constants';
import { selectAuth } from '../../../features/store/slices/auth';
import { LinkWithLocale } from '../link-with-locale';

export const Header = () => {
  const { t } = useTranslation('common');

  const { onClose, tg } = useTelegram();

  const { order, step } = useAppSelector(selectOrder);
  const { user } = useAppSelector(selectAuth);
  const { updateStep } = useActions();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmitToLink = () => {
    setIsOpen(false);
    updateStep('CHANNELS');
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  if (!user) return null;

  return (
    <div className={s.header}>
      <Button onClick={toggleDrawer}>
        <MenuOutlined />
      </Button>
      <Drawer
        title={
          <div className="flex justify-between items-center gap-2">
            <p>{user ? user?.fullName : t('sidebar.not_auth')}</p>
            <LangSwitcher />
          </div>
        }
        placement={'left'}
        closable={true}
        onClose={toggleDrawer}
        closeIcon={<CloseCircleOutlined />}
        open={isOpen}
        key={'left'}
        footer={
          <div>
            {tg.initDataUnsafe.user ? (
              <Button type="dashed" onClick={onClose}>
                {t('sidebar.close')}
              </Button>
            ) : (
              <Logout />
            )}
          </div>
        }
      >
        <Menu onSubmitToLink={onSubmitToLink} />
      </Drawer>
      <CheckPermissionContent permissionId={PERMISSION_CREATE_ORDER}>
        {order.channels.length > 0 && step === 'CHANNELS' && (
          <div className="flex items-center gap-4 mx-3">
            <span className="text-sm">
              {t('selected')}: {order.channels.length}
            </span>
            <LinkWithLocale to="/create_order" onClick={() => updateStep('SELECT_FORMAT')}>
              {t('sidebar.next')}
            </LinkWithLocale>
          </div>
        )}
      </CheckPermissionContent>
    </div>
  );
};
