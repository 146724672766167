import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

import { OrderVaritantType } from '../../../types/order';

import type { PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  category: string | null;
  isMandatoryApproval: boolean | null;
  isOpenMainFilterModal: boolean;
  searchStr: string;
  variant: OrderVaritantType;
}

const initialState: FilterState = {
  category: 'ALL',
  isMandatoryApproval: null,
  isOpenMainFilterModal: false,
  searchStr: '',
  variant: 'BANNER',
};

export const mainFilterSlice = createSlice({
  name: 'main-filter',
  initialState,
  reducers: {
    setIsMandatoryApprovalMainFilter(state, action: PayloadAction<boolean | null>) {
      state.isMandatoryApproval = action.payload;
    },
    setCategoryFilterMainFilter(state, action: PayloadAction<string | null>) {
      state.category = action.payload;
    },
    setSearchStrMainFilter(state, action: PayloadAction<string>) {
      state.searchStr = action.payload;
    },
    setIsOpenMainFilterModal(state, action: PayloadAction<boolean>) {
      state.isOpenMainFilterModal = action.payload;
    },
    setVariantMainFilter(state, action: PayloadAction<OrderVaritantType>) {
      state.variant = action.payload;
    },
  },
});

export const mainFilterActions = mainFilterSlice.actions;

export const selectMainFilter = (state: RootState) => state.mainFilter;

export default mainFilterSlice.reducer;
