import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';

import { router } from './features/router';
import { Loader } from './components/loader';

export const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
