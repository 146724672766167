import { Menu as AntMenu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useTelegram } from '../../../features/hooks/useTelegram';
import {
  LINK_TO_CHANNELS,
  LINK_TO_DICTIONARY,
  LINK_TO_MAIN,
  LINK_TO_MARGIN_REPORT,
  LINK_TO_ORDERS,
  LINK_TO_PAYMENT_CONFIRMATION,
  LINK_TO_PRICES,
  LINK_TO_PRICE_CALCULATOR,
  LINK_TO_REPORT_ACCOUNTING,
  LINK_TO_ROLE_MANAGEMENT,
  PERMISSION_VIEW_ACCEPT_TRANSACTIONS,
  PERMISSION_VIEW_CALC_PRICES,
  PERMISSION_VIEW_CHANNEL_PRICES,
  PERMISSION_VIEW_DICTIONARY,
  PERMISSION_VIEW_MARGIN_REPORT,
  PERMISSION_VIEW_ORDERS_AND_VIEW_ORDER,
  PERMISSION_VIEW_PERMISSIONS,
  PERMISSION_VIEW_REPORT_ACCOUNTING,
  ROUTE_CHANNELS,
  ROUTE_DICTIONARY,
  ROUTE_MAIN,
  ROUTE_MARGIN_REPORT,
  ROUTE_ORDERS,
  ROUTE_PAYMENT_CONFIRMATION,
  ROUTE_PRICES,
  ROUTE_PRICE_CALCULATOR,
  ROUTE_REPORT_ACCOUNTING,
  ROUTE_ROLE_MANAGEMENT,
} from '../../../features/constants';
import { checkPermission } from '../../../features/helpers/check-permission';
import { useAppSelector } from '../../../features/hooks/useRedux';
import { selectAuth } from '../../../features/store/slices/auth';
import { LinkWithLocale } from '../link-with-locale';
import { AWithLocale } from '../a-with-locale';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const Menu = ({ onSubmitToLink }: { onSubmitToLink: () => void }) => {
  const { tg } = useTelegram();
  const { user } = useAppSelector(selectAuth);
  const { t } = useTranslation('common');

  const browserMenu: MenuProps['items'] = [
    checkPermission({ permissionId: PERMISSION_VIEW_MARGIN_REPORT, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_MARGIN_REPORT} onClick={onSubmitToLink}>
            {t('sidebar.margin_report')}
          </LinkWithLocale>,
          'margin_report',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_REPORT_ACCOUNTING, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_REPORT_ACCOUNTING} onClick={onSubmitToLink}>
            {t('sidebar.report_accounting')}
          </LinkWithLocale>,
          'report_accounting',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_ACCEPT_TRANSACTIONS, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_PAYMENT_CONFIRMATION} onClick={onSubmitToLink}>
            {t('sidebar.payment_confirmations')}
          </LinkWithLocale>,
          'payment_confirmations',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_CALC_PRICES, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_PRICES} onClick={onSubmitToLink}>
            {t('sidebar.price_calculation')}
          </LinkWithLocale>,
          'prices',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_PERMISSIONS, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_ROLE_MANAGEMENT} onClick={onSubmitToLink}>
            {t('sidebar.role_management')}
          </LinkWithLocale>,
          'role-management',
        )
      : null,
  ];

  const mobileMenu: MenuProps['items'] = [
    getItem(
      <LinkWithLocale to={LINK_TO_MAIN} onClick={onSubmitToLink}>
        {t('sidebar.main')}
      </LinkWithLocale>,
      'home',
    ),
    checkPermission({ permissionId: PERMISSION_VIEW_ORDERS_AND_VIEW_ORDER, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_ORDERS} onClick={onSubmitToLink}>
            {t('sidebar.orders')}
          </LinkWithLocale>,
          'orders',
        )
      : null,

    getItem(
      <LinkWithLocale to={LINK_TO_CHANNELS} onClick={onSubmitToLink}>
        {t('sidebar.channels')}
      </LinkWithLocale>,
      'channels',
    ),
    getItem(
      <AWithLocale href={LINK_TO_MARGIN_REPORT} target="_blank" rel="noreferrer">
        {t('sidebar.personal_area')}
      </AWithLocale>,
      'margin_report',
    ),
    checkPermission({ permissionId: PERMISSION_VIEW_CHANNEL_PRICES, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_PRICE_CALCULATOR} onClick={onSubmitToLink}>
            {t('sidebar.channel_prices')}
          </LinkWithLocale>,
          'channel_prices',
        )
      : null,
    checkPermission({ permissionId: PERMISSION_VIEW_DICTIONARY, user: user! })
      ? getItem(
          <LinkWithLocale to={LINK_TO_DICTIONARY} onClick={onSubmitToLink}>
            {t('sidebar.dictionary')}
          </LinkWithLocale>,
          'dictionary',
        )
      : null,
  ];

  return (
    <AntMenu
      style={{ width: '100%' }}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      mode="inline"
      items={tg.initDataUnsafe.user ? mobileMenu : browserMenu}
    />
  );
};
