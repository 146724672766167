import { LoadingOutlined } from "@ant-design/icons";

import s from "../styles/loader.module.scss";

export const Loader = () => {
  return (
    <div className={s.loader}>
      <LoadingOutlined />
    </div>
  );
};
