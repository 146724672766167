import { Link, LinkProps, useParams } from 'react-router-dom';

export const LinkWithLocale = (args: LinkProps) => {
  const { locale } = useParams();

  if (!locale) {
    console.log('Локали нет, нужен редирект на страницу с ошибкой');
    return null;
  }

  return <Link {...args} to={`/${locale!}${args.to}`} />;
};
